#blogPostsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
}

.blogPost {
    flex-basis: 100%;
    background-color: var(--primary-color-light-2);
    /* background-image: linear-gradient(var(--gradient)); */
    padding: 16px;
}

@media screen and (min-width: 768px) {
    .blogPost {
        flex-basis: min(40%, 500px);
    }
}


