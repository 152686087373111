#services-hero-section {
    height: 90vh;
    background: rgba(69, 69, 69, 0.236) url("../images/happy-patient.jpg");
    background-blend-mode: darken;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#services-hero-section * {
    color: white;
    text-align: center;
}

/*	
	------------------------------
	Services 
	------------------------------
*/

.service-description-and-animation {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.service-animation {
    flex-shrink: 0;
    flex-basis: 200px;
    margin: -40px;
    margin-bottom: auto;
}

@media screen and (min-width: 768px) {
    .service-description-and-animation {
        flex-wrap: nowrap;
    }
}


