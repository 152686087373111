#phones-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
}

#phones-container a {
	font-size: 22px;
}

#phones-container img {
	width: 22px;
}

#social-icons-container {
	display: flex;
	justify-content: center;
	gap: 32px;
}



/* TO DO  */
form .checkbox-container input{
	height: 30px;
	border: 1px solid black;
}

form .form__button {
	height: 30px;
	width: 200px;
	padding: 12px;
	border: 1px solid black;
}




