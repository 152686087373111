footer {
	text-align: center;
	background-image: linear-gradient(#ede7f6,#bbb5c3);
	clear: both;
}

#footer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 16px;
}

#footer-contacts, 
#footer-map {
	width: 100%;
}

#footer-map {
	height: 300px;
	border: 1px solid black;
}

@media screen and (min-width: 768px) {
	#footer-contacts, 
	#footer-map {
		max-width: 40vw;
	}
}

footer h1 {
	padding-bottom: 24px;
}


.footer-contactText {
	color: black;
}

#footerMenu ul {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	text-align: left;
}

#footerMenu li {
	width: 50%;
	list-style: none;
}

.footerMenu-link {
	text-decoration: none;
	color: black;
}

@media screen and (min-width: 768px) {

	#footerMenu ul {
		text-align: center;
		flex-wrap: nowrap;
		gap: 16px;
		justify-content: space-around;
	}

	#footerMenu li {
		width: auto;
	}

}