/* Variables  */

:root {
	--global-box-shadow: 2px 2px 14px rgb(56, 56, 56);
	--global-horizontal-padding: 6vw;
	--global-vertical-padding: 3vh;
	--global-transition: 0.2s ease-out;
	--global-border-radius: 0;
	--global-transform-scale: 1.1;
	--global-transform: translateY(-12px);
	--min-width-laptop-breakpoint: 768px;
	--hamburgerLine-start-position: 16px;
	--hamburgerLine-end-position: 24px;


	--primary-color: rgb(216, 137, 228);;
	--primary-color-light: rgb(237, 173, 247);
	--primary-color-light-2: rgb(252, 233, 255);
	--primary-color-dark: rgb(228, 124, 244);
	--gradient: 33deg, var(--primary-color-light-2), 80%, var(--primary-color-light);

}

* {
	box-sizing: border-box;
}

body {
	margin: 0px;
	padding: 0px;
}

header, 
section, 
footer {
	padding: 
		var(--global-vertical-padding) 
		var(--global-horizontal-padding) 
		var(--global-vertical-padding)
		var(--global-horizontal-padding);
}

/*	
	------------------------------
	Global Typography 
	------------------------------
*/

h1, 
h2, 
h3 {
	color: black;
	font-family: 'DM Serif Display', sans-serif;
	line-height: 1.5;
}

h1 {
	font-size: 30px;
	text-align: center;
	text-decoration: underline;
	font-weight: bold;
}


h2 {
	font-size: 26px;
	text-align: center;
}

h3 {
	font-size: 22px;
	text-align: left;
}

p, 
input, 
select,
label, 
ul, 
ol, 
a,
span {
	font-family:  'Montserrat', sans-serif;
	font-size: 18px;
	line-height: 1.5;
}

*::after, *::before {
	font-family:  'Montserrat', sans-serif;
	font-size: 18px;
	line-height: 1.5;
	color: red;
}

.headline {
	text-align: center;
	font-size: 20px;
	font-style: italic;
	padding-bottom: 16px;
}


a {
	color: var(--primary-color-dark);
}

em {
	font-weight: 600;
}

/*	
	------------------------------
	Global Button 
	------------------------------
*/

.button, 
button {
	display: flex;
	align-items: center;
	justify-content: center;	
	padding: 16px 0;
}

.button a, 
button {
	width: clamp(200px, 300px, 400px);
	text-align: center;
	background-color: var(--primary-color-dark);
	font-family: 'Montserrat';
	color: black;
	font-weight: bold;
	text-decoration: none;
	font-size: 22px;
	cursor: pointer;
	padding: 16px 8px;
	transition: var(--global-transition);
	border-radius: var(--global-border-radius);
}

.button a:hover,
button:hover {
	transform: var(--global-transform);
	box-shadow: var(--global-box-shadow);
	color: white;
}

/*	
	------------------------------
	Global Image 
	------------------------------
*/

.global-image-preset {
	box-shadow: var(--global-box-shadow);
	border-radius: var(--global-border-radius);
	transition: var(--global-transition);
	
}

.global-image-preset:hover {
	transform: var(--global-transform);
}

/*	
	------------------------------
	Contact and Social Media Icons 
	------------------------------
*/

.contactIcon {
	width: 18px;
}

.contactTextLink {
	text-decoration: none;
	color: black;
}

.socialMedia {
	width: 40px;
	margin: 0 8px;
	transition: var(--global-transition);
}

.socialMedia:hover {
	transform: var(--global-transform);
}

/* FORM */

.form-container {
	display: flex;
	justify-content: center;
}

form {
	width: 500px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: center;
}

form .input-field {
	width: 500px;
	width: 100%;
	height: 30px;
	border: 1px solid black;
	margin-bottom: 16px;
	padding: 12px;
}

form .checkbox-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

form label {
	align-self: flex-start;
	text-align: left;
}