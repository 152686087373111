
/*	
	------------------------------
	Hero Section 
	------------------------------
*/

#heroSection {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	height: 90vh;
	background-image: linear-gradient(white,#bbb5c3);
	padding-bottom: 0;
	padding-top: 0;
}

#heroSection-info {
	display: flex;
	flex-direction: column;
	justify-content: flex-review-body-start;
	flex-wrap: wrap;
	text-align: center;
}

#heroSection-quote {
	margin: 0;
	text-align: right;
}

#heroSection-imageContainer {
	/* Without setting height to any value (1 px in this case)
	the flex property doesn't work properly for some reason */
	height: 1px;
	flex: 1 1 auto;
	display: flex;
	justify-content: center;
}

#heroSection-image {
	height: 100%;
}

/* Responsiveness */

@media screen and (min-width: 768px) {
	
	#heroSection {
		flex-direction: row;
		align-items: center;
	}

	#heroSection-info {
		width: 50%;
	}
	
	#heroSection-imageContainer {
		height: 70%;
		width: 50%;
		align-self: flex-end;
	}
}

/*	
	------------------------------
	Google Reviews Section 
	------------------------------
*/

#reviewsContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	gap: 8px;
}

.review {
	transition: var(--global-transition);
}
.review:hover {
	transform: var(--global-transform);
}

.review-body {
	max-width: 350px;
	height: 140px;
	background-color: #efefef;
	padding: 14px;
	border-radius: 8px;
	overflow-y: auto;
}

.review-body ul {
	display: inline;
	padding: 0px;
	list-style-type: none;
}

.review-body li {
	display: inline;
}

.review-body-star {
	width: 20px;
	height: 20px;
}

.review-body-googleIcon {
	width: 20px;
	height: 20px;
	float: right;
}

.review-body p {
	font-family: 'Open Sans';
	font-size: 16px;
}

.review-triangle {
	width: 0px;
	height: 0px;
	border: 12px solid transparent;
	border-top: 12px solid #efefef;
	border-bottom: 0px;
	position: relative;
	left: 20px;
}

.review-photo {
	width: 45px;
	height: 45px;
	position: relative;
	left: 10px;
	top: 8px;
}

.review-name {
	font-family: 'Open Sans';
	font-weight: bold;
	height: 45px;
	position: relative;
	left: 20px;
	display: inline-block;
	vertical-align: middle;
}


/*	
	------------------------------
	Insurances Section 
	------------------------------
*/

#insurancesContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.insurance-logo {
	width: 150px;
	object-fit: contain;
	transition: var(--global-transition);
}

.insurance-logo:hover {
	transform: var(--global-transform);
}

/*	
	------------------------------
	Dental Services Section 
	------------------------------
*/

#dentalServices-button {
	justify-content: flex-end;
}

/*	
	------------------------------
	You Are In Safe Hands Section 
	------------------------------
*/

#safeHands {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

#safeHands-image {
	max-width: 80vw;
	margin: 16px;
}

@media screen and (min-width: 768px) {
	
	#safeHands-image {
		max-width: 35vw;
	}
	
	#safeHands {
		flex-wrap: nowrap;
	}
}

/*	
	------------------------------
	Floating WhatsApp Button
	------------------------------
*/

/* The following prevents the overflow of Whatsapp Button.
		Which has its review-body-starting animation position 
		beyond the viewport to the right. */
body, section {
	overflow-x: hidden;
}

@keyframes floatingWhatsappButton-appearAnimation {
	from {
		transform: 
			translateX(200px) 
			rotate(360deg);
	}
	to {
		transform: 
			translateX(0)
			rotate(0deg);
	}
}

#floatingWhatsappButton {
	width: 60px;
	height: 60px;
	position: sticky;
	float: right;
	bottom: 6vh;
	margin-right: var(--global-horizontal-padding);

	animation-name: floatingWhatsappButton-appearAnimation;
	animation-timing-function: ease-out;
	animation-duration: 0.8s;
	animation-delay: 2s;
	animation-fill-mode: backwards;

}

#floatingWhatsappButton-image{
	width: 60px;
	box-shadow: var(--global-box-shadow);
	border-radius: 22%;
	transition: var(--global-transition);
}

#floatingWhatsappButton-image:hover{
	transform: var(--global-transform);
}

