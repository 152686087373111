#team-members-container {
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	gap: 32px;
}

.team-member {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	background-color: rgba(184, 183, 186, 0.24);
	padding: 24px;

}

.team-member * {
	flex-basis: 100%;
	margin: 4px;
}

.team-member img {
	flex-basis: 60%;
	width: 60%;
	box-shadow: 2px 2px 12px rgb(50, 50, 50);;
}

.team-member-position {
	color: grey;
}

@media screen and (min-width: 768px) {
	#team-members-container {
		flex-wrap: nowrap;
	}
}