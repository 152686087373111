#phones-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
}

#phones-container a {
	font-size: 22px;
}

#social-icons-container {
	display: flex;
	justify-content: center;
	gap: 32px;
}

/*	
	------------------------------
	Form
	------------------------------
*/

form {
	/* display: flex;
	flex-direction: column;
	gap: 16px; */
}

form h3 {
	margin-bottom: 0;
}

.form-headline {
	text-align: left;
	margin-top: 0;
}

@keyframes errorMessage-animation {
	from {
		transform: scaleY(0);
		transform-origin: top;
	}
	to {
		transform: scaleY(1);
	}
}

.form-inputContainer:has(
	.form-input:not(
		:placeholder-shown, :focus, :valid))::after {
	display: block;
	content: attr(errorMessage);
	animation-name: errorMessage-animation;
	animation-duration: 0.5s;
}

.form-input:not(
		:placeholder-shown, :focus, :valid) {
	border: 2px solid red;
	color: red;
}

.form-input {
	height: 50px;
	width: min(100%, 500px);
	padding: 12px;
	border: 1px solid rgb(0, 0, 0);
}

.form-inputContainer-buttonClicked:has(
	.form-input:invalid)::after {
	content: attr(errorMessage);
}

.form-input-buttonClicked:invalid {
	border: 2px solid red;
	color: red;
}

.form-input:not(:placeholder-shown, #date, #time):valid {
	border: 2px solid forestgreen;
}

#form-inputContainer-time:focus-within::after {
	content: "We are open 08:00 - 17:00";
	color: black;
}

.form-checkboxContainer {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 24px;
}

.form-checkbox {
	width: 30px;
	height: 30px;
}






